import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Box, Grid, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'

import Loader from '@/components/Loader/Loader'

import ExamStatusCard from './component/ExamStatusCard'
import ExamUserModal from './component/ExamsUsersModal/ExamUserModal'
import { useExam } from './hooks/useExam'
import { stores } from '@/stores/index'
import CustomModal from '@/components/Modal'

export interface IExamCardStatus {
  status: string
  course: string | number
  numOfQuest: number
  time: any
  categories: string[]
  expireDate: Date | any
  timeDate: Date | any
  isExamCompleted: boolean
  handleSelectExam: (e: any) => void
  isActive: true
  isUpComing: true
}

interface IExamsData {
  upcoming: IExam[]
  active: IExam[]
  expired: IExam[]
}

const UserDashboardPage = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenPermission,
    onOpen: onOpenPermission,
    onClose: onClosePermission,
  } = useDisclosure()
  const route = useRouter()

  const { isLoading, exams } = useExam()
  const currentOrganizationName = stores.organization.getOrganization()?.name

  const [examSelect, setExamSelect] = useState<IExam | any>()
  const [examsData, setExamsData] = useState<IExamsData>()

  useEffect(() => {
    if (exams && exams.length > 0) {
      const upcoming = exams.filter((exam: IExam) => {
        const now = moment(exam.server_current_time).tz(exam.timezone)
        const start = moment(exam.start_date).tz(exam.timezone)
        return now.isBefore(start)
      })
      const active = exams.filter(exam => {
        const now = moment(exam.server_current_time).tz(exam.timezone)
        const start = moment(exam.start_date).tz(exam.timezone)
        const end = moment(exam.end_date).tz(exam.timezone)
        return now.isBetween(start, end)
      })
      const expired = exams.filter(exam => {
        const now = moment(exam.server_current_time).tz(exam.timezone)
        const end = moment(exam.end_date).tz(exam.timezone)
        return now.isAfter(end)
      })
      setExamsData({ upcoming, active, expired })
    }
  }, [exams])

  const handleSelectExam = (exam: IExam) => {
    setExamSelect({ ...exam, currentOrganizationName })
    if (
      exam.enable_proctoring &&
      exam.status !== 'upcoming' &&
      exam.status !== 'expired'
    ) {
      onOpenPermission()
    } else onOpen()
  }

  const handleCameraActivation = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(stream => {
        /* use the stream */
        onClosePermission()
        onOpen()
      })
      .catch(err => {
        toast({
          title: err.message,
          description: `${err.message} because camera access was denied. If you want to make another attempt kindly close this page and try again`,
          status: 'warning',
          duration: 9000,
          isClosable: true,
        })
        onClosePermission()
      })
  }

  return (
    <>
      <Box>
        <Text fontWeight="medium" fontSize="1.25rem" mb="1rem">
          Active Exams
        </Text>
        <Grid
          justifyContent="space-between"
          gap={2}
          gridTemplateColumns={[
            '1fr',
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
          ]}
          rowGap="1.875rem"
          columnGap="1.5rem"
        >
          {examsData && examsData.active.length > 0 ? (
            examsData.active.map((data, i) => (
              <ExamStatusCard
                handleSelectExam={() =>
                  handleSelectExam({ ...data, status: 'active' })
                }
                key={data.id}
                data={data}
                status="active"
              />
            ))
          ) : (
            <Text color="gray" fontSize="sm">
              No Active Exams
            </Text>
          )}
        </Grid>
      </Box>
      <Box mt="4.1rem">
        <Text fontWeight="medium" fontSize="1.25rem" mb="1rem">
          Upcoming Exams
        </Text>
        <Grid
          justifyContent="space-between"
          gap={2}
          gridTemplateColumns={['1fr', 'repeat(2, 1fr)']}
          rowGap="1.875rem"
        >
          {examsData && examsData.upcoming.length > 0 ? (
            examsData.upcoming.map(data => (
              <ExamStatusCard
                handleSelectExam={() =>
                  handleSelectExam({ ...data, status: 'upcoming' })
                }
                key={data.id}
                data={data}
                status="upcoming"
              />
            ))
          ) : (
            <Text color="gray" fontSize="sm">
              No Upcoming Exams
            </Text>
          )}
        </Grid>
      </Box>

      <Box mt="4.1rem">
        <Text fontWeight="medium" fontSize="1.25rem" mb="1rem">
          Concluded Exams
        </Text>
        <Grid
          justifyContent="space-between"
          gap={2}
          gridTemplateColumns={['1fr', 'repeat(2, 1fr)']}
          rowGap="1.875rem"
        >
          {examsData && examsData.expired.length > 0 ? (
            examsData.expired.map(data => (
              <ExamStatusCard
                handleSelectExam={() =>
                  handleSelectExam({ ...data, status: 'expired' })
                }
                key={data.id}
                data={data}
                status="expired"
                isExamCompleted={true}
              />
            ))
          ) : (
            <Text color="gray" fontSize="sm">
              No Concluded Exams
            </Text>
          )}
        </Grid>
      </Box>

      <CustomModal
        type="error"
        onClose={onClosePermission}
        isOpen={isOpenPermission}
        handleClick={handleCameraActivation}
        message={
          'This exam is being monitored. You are required to allow access to your camera, otherwise you will not be able to take this exam. Kindly click on proceed'
        }
      />
      {/* Exam User Modal */}
      <ExamUserModal
        isOpen={isOpen}
        onClose={onClose}
        data={examSelect}
        onClick={() => {
          // navigate to exam page
          route.push('/exam/start/' + examSelect.id)
          // close modal
          onClose()
          // enter full screen mode
          document.documentElement.requestFullscreen()
        }}
      />
      <Loader isLoading={isLoading} />
    </>
  )
}

export default observer(UserDashboardPage)
